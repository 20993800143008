<template>
  <div id="app" class="mx-auto relative">
    <div class="hidden sm:block quote absolute text-center z-10 w-full top-1">
      {{ selectedMessage }}
    </div>
    <ZoomWindow />
    <!-- <div class="browser-mockup"> -->

    <p v-if="isPreview()">
      <span v-for="index in 31" :key="index" class="">
        <router-link :to="'/day/' + index + '?preview=1'">
          {{ index }}
        </router-link>
      </span>
    </p>
  </div>
</template>

<style lang="scss">
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.browser-mockup {
  /* margin:  */
  /* max-width: 80%; */
  border-top: 2em solid rgba(230, 230, 230, 0.7);
  box-shadow: 0 0.1em 1em 0 rgba(0, 0, 0, 0.4);
  position: relative;
  border-radius: 10px;
}
$size_multiplier: 1.25em;
.browser-mockup:before {
  display: block;
  position: absolute;
  content: '';
  top: -1.25em;
  left: 1em;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: #ff5a53;
  box-shadow: 0 0 0 2px #ff5a53, $size_multiplier 0 0 2px #e6c029, $size_multiplier * 2 0 0 2px #53c32b;
}

.browser-mockup.with-tab:after {
  display: block;
  position: absolute;
  content: '';
  top: -2em;
  left: 5.5em;
  width: 20%;
  height: 0em;
  border-bottom: 2em solid white;
  border-left: 0.8em solid transparent;
  border-right: 0.8em solid transparent;
}

.browser-mockup.with-url:after {
  display: block;
  position: absolute;
  content: '';
  top: -1.6em;
  left: 5.5em;
  width: calc(100% - 6em);
  height: 1.2em;
  border-radius: 2px;
  background-color: white;
}

.browser-mockup > * {
  display: block;
}

/* Custom code for the demo */

body {
  background: #e1cf40;
  display: flex;
}

.browser-mockup {
  /* border: solid 1px #888; */
  /* padding: 4rem; */
  margin: 0 auto;
  // height: 90vh;
  // width: 90vw;
  min-height: 700px;
  /* margin: 0 auto; */
  flex: 1;
}

body {
  background-color: #e1cf40;
}

#zoomWindow {
  border-top: 2em solid hsla(0, 0%, 90.2%, 0.7);
  box-shadow: 0 0.1em 1em 0 rgba(0, 0, 0, 0.4);
  position: relative;
  border-radius: 10px;
}
#zoomWindow:before {
  display: block;
  position: absolute;
  content: '';
  top: -1.25em;
  left: 1em;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: #ff5a53;
  box-shadow: 0 0 0 2px #ff5a53, 1.25em 0 0 2px #e6c029, 2.5em 0 0 2px #53c32b;
}

#toolbar {
  background-color: #191a1c;
}

.bg-yellow {
  background-color: rgb(225, 207, 64);
}

#title {
  max-height: 150px;
}

p.lead {
  font-size: 90%;
  line-height: 1.2rem;
}

@media only screen and (min-height: 720px) {
  #title {
    max-height: none;
  }

  p.lead {
    font-size: inherit;
    line-height: inherit;
  }
}

@media only screen and (min-width: 1024px) {
  .grid-container {
    display: grid;
    grid-template-columns: 5.32% 24.73% 39.89% 24.73% 5.32%;
    grid-template-rows: 11.4% 33.2% 8.6% 33.1% 13.7%;
    gap: 0px 0px;
    grid-template-areas:
      'tl tml t tmr tr'
      'tcl m m m tcr'
      'l m m m r'
      'bcl m m m bcr'
      'bl bml b bmr br';
  }
}

.grid-container div {
  background-size: 100% 100%;
  background-image: none;
}

.tl {
  grid-area: tl;
}
.tml {
  grid-area: tml;
}
.grid-container .t {
  grid-area: t;
  background-size: contain !important;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('/img/borders/t.png?v=1606506518299');
}
.tmr {
  grid-area: tmr;
}
.m {
  grid-area: m;
}
.tcl {
  grid-area: tcl;
}
.l {
  grid-area: l;
}
.bcl {
  grid-area: bcl;
}
.bml {
  grid-area: bml;
}
.b {
  grid-area: b;
}
.bmr {
  grid-area: bmr;
}
.br {
  grid-area: br;
}
.bcr {
  grid-area: bcr;
}
.r {
  grid-area: r;
}
.tcr {
  grid-area: tcr;
}
.tr {
  grid-area: tr;
}

@media only screen and (min-width: 1024px) and (min-height: 550px) {
  .grid-container .tl {
    grid-area: tl;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-image: url('/img/borders/tl.png');
  }
  .grid-container .tml {
    grid-area: tml;
    background-repeat: repeat-x;
    background-position: center bottom;
    background-image: url('/img/borders/tml.png');
  }
  .grid-container .tmr {
    grid-area: tmr;
    background-repeat: repeat-x;
    background-position: center bottom;
    background-image: url('/img/borders/tmr.png');
  }
  .grid-container .m {
    grid-area: m;
  }
  .grid-container .tcl {
    grid-area: tcl;
    background-repeat: repeat-y;
    background-position: center center;
    background-image: url('/img/borders/tcl.png');
  }
  .grid-container .l {
    grid-area: l;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('/img/borders/l.png');
  }
  .grid-container .bcl {
    grid-area: bcl;
    background-repeat: repeat-y;
    background-position: center center;
    background-image: url('/img/borders/bcl.png');
  }
  .grid-container .bcr {
    grid-area: bcr;
    background-repeat: repeat-y;
    background-position: right center;
    background-image: url('/img/borders/bcr.png');
  }
  .grid-container .r {
    grid-area: r;
    background-repeat: no-repeat;
    background-position: right center;
    background-image: url('/img/borders/r.png');
  }
  .grid-container .tcr {
    grid-area: tcr;
    background-repeat: repeat-y;
    background-position: right center;
    background-image: url('/img/borders/tcr.png');
  }
  .grid-container .tr {
    grid-area: tr;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-image: url('/img/borders/tr.png');
  }

  .grid-container .bl {
    grid-area: bl;
    background-repeat: no-repeat;
    background-position: center top;
    background-image: url('/img/borders/bl.png');
  }
  .grid-container .bml {
    grid-area: bml;
    background-repeat: no-repeat;
    background-position: center top;
    background-image: url('/img/borders/bml.png');
  }
  .grid-container .b {
    grid-area: b;
    background-repeat: no-repeat;
    background-position: center top;
    background-image: url('/img/borders/b.png');
  }
  .grid-container .bmr {
    grid-area: bmr;
    background-repeat: no-repeat;
    background-position: center top;
    background-image: url('/img/borders/bmr.png');
  }
  .grid-container .br {
    grid-area: br;
    background-repeat: no-repeat;
    background-position: center top;
    background-image: url('/img/borders/br.png');
  }
}

.t {
  a {
    display: block;
    height: 100%;
    width: 100%;
  }
}
</style>

<script>
import ZoomWindow from './components/ZoomWindow.vue';

export default {
  name: 'App',
  components: {
    ZoomWindow
  },
  data() {
    return {
      // messages: ['Message 1', 'Message 2', 'Message 3', 'Message 4', 'Message 5'],
      messages: [
        'Me-EWWWW', // 1
        'This bites', // 2
        'Facetime Continuum', // 3
        'Order Oops', // 4
        'Weakest Link', // 5
        'Multitasking the multitasking', // 6
        'Mourning Commute', // 7
        'Now with more new normal.', // 8
        'Chord Regression', // 9
        'Photo Finished', // 10
        'Do You Hear What I Hear', // 11
        'Stranger Danger', // 12
        'Silent Night', // 13
        'Stranger Danger', // 14
        'Everybody’s a critic', // 15
        'Ding Dong Dash', // 16
        'Doody Calls', // 17
        'A little privacy?', // 18
        'Two-Spaced', // 19
        "You're up to... Wait", // 20
        'Ho Ho No.', // 21
        'Turn me down the Headphones', // 22
        'I feel Unseen', // 23
        'Host with the Least', // 24
        'Home for the everydays.', // 25
        'Together again, again.', // 26
        'Sharing is Scaring', // 27
        'Holiday Spirits', // 28
        'You go First', // 29
        'Banking on Grapes', // 30
        'Time to Look Ahead' // 31
      ],
      defaultMessage: 'Aloysius Butler & Clark Ad-Vent Calendar',
      selectedMessage: ''
    };
  },
  watch: {
    $route(to) {
      //   console.log(to);
      //   console.log(from);
      //   console.log(to.params.id);

      if (to.params.id != null) {
        this.selectedMessage = this.messages[Number(this.$route.params.id) - 1];
      } else {
        this.selectedMessage = this.defaultMessage;
      }
    }
  },
  created() {
    // const idx = Math.floor(Math.random() * this.messages.length);
    if (this.$route.params.id != null) {
      this.selectedMessage = this.messages[Number(this.$route.params.id) - 1];
    } else {
      this.selectedMessage = this.defaultMessage;
    }
  },
  methods: {
    isPreview() {
      return this.$route.query.preview === '1';
    }
  }
};
</script>
