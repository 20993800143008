<template>
  <div class="sm:w-9/12 lg:w-10/12   sm:p-8">
    <div class="sm:flex-1 sm:h-full p-4 sm:pt-8 sm:pr-8 sm:pb-6 sm:pl-8 grid-container ">
      <div class="tl hidden sm:block"></div>
      <div class="tml hidden sm:block"></div>
      <div class="t hidden sm:block"><a href="https://www.abccreative.com"></a></div>
      <div class="tmr hidden sm:block"></div>
      <div class="m">
        <a class="block lg:hidden" href="https://www.abccreative.com">
          <img class=" w-full" src="https://cdn.glitch.com/30696a08-46d1-4fdc-869e-914ba031e6f5%2Ft.png?v=1606506518299"
        /></a>
        <img
          id="title"
          class="m-auto w-full md:w-3/4 mb-4 sm:mb-0"
          src="https://cdn.glitch.com/30696a08-46d1-4fdc-869e-914ba031e6f5%2Fadvent-logo.svg?v=1606490620925"
        />
        <p class="lead text-xs sm:text-sm md:text-lg mx-0 md:mx-8 lg:mx-32 mt-1 sm:mt-8 text-center">
          It’s been quite a year. Or has it been 10? Figuring there was no way we were waiting until 11:59 on December
          31 to start counting down, we created a little countdown of our own. So join us as we air our grievances—and
          inch closer to 2021 with
          <span class="whitespace-nowrap">a fresh perspective.</span>
        </p>
      </div>
      <div class="tcl hidden sm:block"></div>
      <div class="l hidden sm:block"></div>
      <div class="bcl hidden sm:block"></div>
      <div class="bl hidden sm:block"></div>
      <div class="bml hidden sm:block"></div>
      <div class="b hidden sm:block"></div>
      <div class="bmr hidden sm:block"></div>
      <div class="br hidden sm:block"></div>
      <div class="bcr hidden sm:block"></div>
      <div class="r hidden sm:block"></div>
      <div class="tcr hidden sm:block"></div>
      <div class="tr hidden sm:block"></div>
    </div>
    <div class="social">
      <span class="share-text">Share:</span>
      <ShareNetwork network="facebook" url="https://ad-vent.abccreative.com">
        <i class="fa fa-fw fa-facebook "></i>
      </ShareNetwork>
      <ShareNetwork network="twitter" url="https://ad-vent.abccreative.com" title="">
        <i class="fa fa-fw fa-twitter "></i>
      </ShareNetwork>
      <ShareNetwork network="linkedin" url="https://ad-vent.abccreative.com">
        <i class="fa fa-fw fa-linkedin "></i>
      </ShareNetwork>
      <!-- <a href="https://www.instagram.com/abc_creative/"> <i class="fa fa-fw fa-instagram "></i></a> -->
      <!-- <a href="https://www.facebook.com/ABCCreativeIntelligence/"> <i class="fa fa-fw fa-facebook "></i></a> -->
      <!-- <a href="https://twitter.com/abc_Creative"> <i class="fa fa-fw fa-twitter "></i></a> -->
      <!-- <a href="https://www.linkedin.com/company/57729"> <i class="fa fa-fw fa-linkedin "></i></a> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home'
};
</script>

<style lang="scss">
.social {
  .share-text {
    font-size: 80%;
    display: inline-block;
    margin-right: 1rem;
  }
  a {
    background: #e1cf40;
    color: black;
    padding: 0.1em 0.125em;
    display: inline-block;
    margin-right: 1rem;
    border-radius: 8px;
    &:hover {
      color: white;
    }
  }
}
.fa-stack {
  // .fa-square {
  background: green;
  // }
}
.nowrap {
  white-space: nowrap;
}
.intro {
  position: relative;
  .advent-frame {
    position: absolute;
    top: 0%;
    width: 960px;
    left: 50%;
    transform: translate(-50%);
  }

  .frame-inner {
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translate(-50%);
    width: 600px;
  }
}
</style>
