import Vue from 'vue';
import VueRouter from 'vue-router';
import Day from '../components/Day.vue';
import Home from '../components/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  { path: '/day/:id', component: Day }
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

export default router;
