<template>
  <div class="selectors">
    <ul>
      <li v-for="index in 31" :key="index" class="">
        <div v-if="compareDate(index) || isPreview()">
          <router-link :to="'/day/' + index + getRoutePrefix()">
            <img
              :alt="index"
              :src="require('./../assets/numbers/number-black-' + index + '.png')"
              class="img--rollover"
            />
            <img
              :data-index="dayHasContent(index)"
              :alt="index"
              :src="require('./../assets/numbers/number-thumb-' + index + '.jpg')"
              class="img--off"
            />
          </router-link>
        </div>
        <div v-else>
          <img :alt="index" :src="require('./../assets/numbers/number-black-' + index + '.png')" class=" " />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'Selectors',
  created() {
    /* global flowplayer */
    const playerScript = document.createElement('script');
    playerScript.type = 'text/javascript';
    playerScript.async = true;
    playerScript.onload = () => {
      flowplayer.cloud.then(() => {
        const playerInfos = document.getElementsByClassName('flowplayer');
        Array.prototype.forEach.call(playerInfos, playerInfo => {
          const player = flowplayer(playerInfo, {
            token:
              'eyJraWQiOiJXT1pKeVVVUEcybmIiLCJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjIjoie1wiYWNsXCI6NCxcImlkXCI6XCJXT1pKeVVVUEcybmJcIixcImRvbWFpblwiOltcImFkLXZlbnQuYWJjY3JlYXRpdmUuY29tXCJdfSIsImlzcyI6IkZsb3dwbGF5ZXIifQ.unKRycANqScCY_z5L_RgEyl4d4mfujLXrOn9znWuoWC2wiIm2_6oR8u8bZpycSe972MtUZ81NtBLIx7Yz7g-NQ'
          });
          player.muted = false;
          player.setSrc(playerInfo.dataset.video);
        });
      });
    };
    playerScript.src = `//cdn.flowplayer.com/players/3c0df10f-121b-4183-b1e9-1c915fe905b6/native/flowplayer.async.js`;
    document.body.appendChild(playerScript);
  },
  methods: {
    isPreview() {
      return this.$route.query.preview === '1';
    },
    getRoutePrefix() {
      let previewString = '';
      if (this.isPreview()) {
        previewString = '?preview=1';
      }
      return previewString;
    },
    compareDate(index) {
      return moment(`2020-12-${index}`, 'YYYY-MM-DD').isSameOrBefore(moment(), 'day');
      // return true;
    },
    dayHasContent(index) {
      const hasContent = false;
      const dayId = index;
      const dayPath = `/partials/${dayId}.html`;
      this.axios
        .get(dayPath, {})
        .then(() => {
          // handle success
          this.hasContent[index] = true;
          // console.log(hasContent);
        })
        .catch(error => {
          // handle error
          // console.log(error);
        })
        .then(error => {
          // always executed
        });
      // return true;
      return hasContent;
    }
  }
};
</script>

<style scoped lang="scss">
.selectors {
  flex-basis: 18%;
  // /* height: 100%; */
  height: 93vh;
  // max-height: 650px;
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    margin-bottom: 1rem;
    // margin-right: 1rem;

    // cursor: pointer;
    a {
      display: block;
      background: #e1cf40;
      transition: background-color 250ms ease-out;
      &:hover {
        background: #e1cf40;

        .img--off {
          display: none;
        }
        .img--rollover {
          display: block;
        }
      }
    }
    img.img--off {
      display: block;
    }
    img.img--rollover {
      display: none;
    }

    a.router-link-exact-active.router-link-active {
      background: none;

      .img--off {
        display: none;
      }
      .img--rollover {
        display: block;
      }
    }
  }
  img {
    max-width: 100%;
  }
}
</style>
