<template>
  <div id="zoomWindow" class="2xl:container mx-auto h-full">
    <div class="h-full bg-black text-white flex flex-col">
      <div class="flex-1 bg-black overflow-hidden">
        <div class="flex flex-col sm:flex-row h-full overflow-y-scroll sm:overflow-y-hidden sm:9/12">
          <transition name="fade" mode="out-in">
            <router-view />
          </transition>
          <div class="sm:flex-none w-full sm:w-3/12 lg:w-2/12 h-full relative">
            <div class="sm:overflow-y-scroll sm:h-full">
              <div class="m-4 h-0">
                <Selectors class="hidden sm:block" />
                <div class="sm:hidden">
                  <DayTile v-for="index in 31" :index="index" v-bind:key="index" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="toolbar" class="flex-none">
        <div class="flex">
          <div class="flex-none">
            <img
              class="pl-4"
              src="https://cdn.glitch.com/30696a08-46d1-4fdc-869e-914ba031e6f5%2Ftoolbar-left.jpg?v=1606489072024"
            />
          </div>
          <div class="flex-1">
            <img
              class=" mx-auto hidden md:block"
              src="https://cdn.glitch.com/30696a08-46d1-4fdc-869e-914ba031e6f5%2Ftoolbar-center.jpg?v=1606485211180"
            />
            <img
              class=" mx-auto hidden sm:block md:hidden"
              src="https://cdn.glitch.com/30696a08-46d1-4fdc-869e-914ba031e6f5%2Ftoolbar-center-sm.jpg?v=1606488894370"
            />
          </div>
          <div class="flex-none">
            <img
              class="h-12"
              src="https://cdn.glitch.com/30696a08-46d1-4fdc-869e-914ba031e6f5%2Ftoolbar-leave.jpg?v=1606485211511"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Selectors from './Selectors.vue';

import DayTile from './DayTile.vue';

export default {
  name: 'Advent',
  components: { Selectors, DayTile },
  props: {
    msg: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}

a {
  color: #42b983;
}

footer {
  height: 53px;
}

#toolbar {
  img {
    height: 53px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
