<template>
  <div class="dayTileContainer dayTile sm:w-9/12 lg:w-10/12 relative flex-1 flex-col sm:self-center mb-4">
    <div class="dayTileWrapper relative justify-center items-center flex-1 overflow-hidden items-center flex-col">
      <img
        :alt="index"
        :src="require('./../assets/numbers/number-black-' + index + '.png')"
        v-bind:class="{ active: showNumber }"
      />

      <div v-bind:class="{ active: !showNumber }" v-html="adventContent"></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'DayTile',
  props: {
    msg: String,
    index: Number
  },
  data() {
    return {
      adventContent: '',
      hasNext: false,
      dayId: Number(this.index),
      showNumber: true
    };
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();

    // console.log("moment().date() = " + moment().date());
  },
  methods: {
    fetchData() {
      // let dayId = this.index;
      const dayPath = `/partials/${this.index}-mobile.html`;
      this.axios
        .get(dayPath)
        .then(response => {
          // console.log(response.data);
          this.adventContent = response.data;
          this.showNumber = moment().date() < this.index;
        })
        .catch(error => {
          // console.log("ERROR");
          // console.log(error);
          // this.adventContent = 'Come back soon!';
          // this.adventContent = '';
        })
        .then(() => {
          // always executed
        });
    }
  }
};
</script>

<style lang="scss">
.close {
  a {
    display: block;
    background: black;
    // height: 50px;
    // width: 50px;
    padding: 1rem;
    font-size: 2rem;
    line-height: 1;
    transition: all 25ms ease-out;
    &:hover {
      background: #e1cf40;
      color: black;
    }
  }
}
.signal {
  font-family: Lucida Grande, Lucida Sans Unicode, Lucida Sans, Geneva, Verdana, sans-serif;
  font-size: 12px;
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  // font-weight: ;
  background: black;
}
.nav-button {
  background: rgba(black, 0.9);
  span {
    display: inline-block;
    font-weight: 100;
    font-size: 120%;
    color: #e1cf40;
  }

  a {
    display: block;
    padding: 1rem;
    letter-spacing: 0.1em;
  }
  &:hover {
    background: rgba(#e1cf40, 0.9);

    color: black;
    span {
      color: black;
    }
  }
  &.nav--prev {
    span {
      margin-right: 0.3rem;
    }
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  &.nav--next {
    span {
      margin-left: 0.3rem;
    }
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
.error {
  display: none;
}

.dayTileWrapper {
  > img {
    display: none;
    &.active {
      display: block;
    }
  }
  > div {
    display: none;
    &.active {
      display: block;
    }
  }
}

.is-small .fp-switch {
  position: absolute;
  right: 10px;
  bottom: 30px;
}
</style>
