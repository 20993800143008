import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueSocialSharing from 'vue-social-sharing';
import router from './router';
import App from './App.vue';
import './assets/tailwind.css';

Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.use(VueSocialSharing);
Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered'))
}).$mount('#app');
