<template>
  <div class="dayContainer day sm:w-9/12 lg:w-10/12 sm:self-center">
    <div class="dayWrapper relative overflow-hidden max-w-2xl lg:max-w-5xl mx-auto">
      <div class="close z-10 absolute top-4 right-4">
        <router-link to="/">
          <!-- <img src="./../assets/close-x.jpg" alt="Close"/> -->
          &#10005;
        </router-link>
      </div>

      <div v-if="$route.params.id > 1" class="nav-button z-10 top-2/4 absolute left-0 nav--prev">
        <router-link :to="'/day/' + (Number($route.params.id) - 1)"><span> &#10216;</span> PREV</router-link>
      </div>
      <div v-if="hasMore()" class="nav-button z-10 top-2/4 absolute right-0 nav--next">
        <!-- <div class="nav-button z-10 top-2/4 absolute right-0 nav--next"> -->
        <router-link :to="'/day/' + (Number($route.params.id) + 1)">NEXT <span> &#10217;</span></router-link>
      </div>
      <div v-html="adventContent"></div>

      <!-- <div class="signal absolute left-0 bottom-0">
        <i class="fa fa-signal" aria-hidden="true"></i> Day {{ $route.params.id }}
      </div> -->
    </div>

    <!-- <div class="error">
      <h2>Day {{ $route.params.id }}</h2>
      <p>Come back soon!</p>
    </div> -->
  </div>
</template>

<script>
/* global gtag */
import moment from 'moment';

export default {
  name: 'Day',
  props: {
    msg: String
  },
  data() {
    return {
      adventContent: '',
      hasNext: false,
      dayId: Number(this.$route.params.id)
    };
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();

    // console.log(`moment().date() = ${moment().date()}`);
  },
  watch: {
    // call again the method if the route changes
    $route: 'fetchData'
  },
  methods: {
    hasMore() {
      return moment().date() > Number(this.$route.params.id);
    },
    fetchData() {
      // let dayId = this.$route.params.id;
      const dayPath = `/partials/${this.$route.params.id}.html`;
      if (typeof gtag !== 'undefined') {
        gtag('event', 'Open', {
          event_category: 'Days',
          event_label: this.$route.params.id
        });
      }
      this.axios
        .get(dayPath)
        .then(response => {
          // console.log(response.data);
          this.adventContent = response.data;
          setTimeout(() => {
            /* global flowplayer */
            const playerInfo = document.getElementById('flowplayer');
            if (playerInfo !== null) {
              const playerScript = document.createElement('script');
              playerScript.type = 'text/javascript';
              playerScript.async = true;
              playerScript.onload = () => {
                flowplayer.cloud.then(() => {
                  const player = flowplayer('#flowplayer');
                  player.muted = false;
                  player.setSrc(playerInfo.dataset.video);
                  setTimeout(() => {
                    player.play();
                  }, 250);
                });
              };
              playerScript.src = `//cdn.flowplayer.com/players/3c0df10f-121b-4183-b1e9-1c915fe905b6/native/flowplayer.async.js`;
              playerInfo.appendChild(playerScript);
            }
          }, 250);
        })
        .catch(error => {
          // console.log("ERROR");
          // console.log(error);
          this.adventContent = 'Come back soon!';
          // this.adventContent = '';
        })
        .then(() => {
          // always executed
        });
    }
  }
};
</script>

<style lang="scss">
.close {
  a {
    display: block;
    background: black;
    // height: 50px;
    // width: 50px;
    padding: 1rem;
    font-size: 2rem;
    line-height: 1;
    transition: all 25ms ease-out;
    &:hover {
      background: #e1cf40;
      color: black;
    }
  }
}
.signal {
  font-family: Lucida Grande, Lucida Sans Unicode, Lucida Sans, Geneva, Verdana, sans-serif;
  font-size: 12px;
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  // font-weight: ;
  background: black;
}
.nav-button {
  background: rgba(black, 0.9);
  span {
    display: inline-block;
    font-weight: 100;
    font-size: 120%;
    color: #e1cf40;
  }

  a {
    display: block;
    padding: 1rem;
    letter-spacing: 0.1em;
  }
  a {
  }
  &:hover {
    background: rgba(#e1cf40, 0.9);

    color: black;
    span {
      color: black;
    }
  }
  &.nav--prev {
    span {
      margin-right: 0.3rem;
    }
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  &.nav--next {
    span {
      margin-left: 0.3rem;
    }
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
.error {
  display: none;
}
</style>
